import React, { useState, useEffect } from 'react';
import Banner from '../components/Banner';
import Speciality from '../components/Speciality';
import myHtml from '../components/Html';
import Conv from '../components/Conv';
import Shiba from '../components/Shiba';
import Sib from '../components/Sib';

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <div className='bg-[#fff3dd]   overflow-x-hidden flex flex-col justify-center items-center'>
      {loading ? (
        // Loader component or loading message
        // <span className="loading loading-ring  w-72 h-72 bg-gray-500"></span>
        <span className="loading loading-dots w-20 h-20 bg-gray-200"></span>
        // <span className="loading loading-bars w-32 h-32 bg-gray-500"></span>

      ) : (
        // Content to display after loading
        <>
        <Sib />
        {/* <Shiba /> */}
        {/* <Conv /> */}
        {/* <myHtml /> */}
          {/* <Banner /> */}
          {/* <Speciality /> */}
        </>
      )}
    </div>
  );
};

export default Home;
